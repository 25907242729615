import React, { useState } from 'react'
import { Modal, Button, Row, Col, Form } from 'react-bootstrap'
import { calculateTotalCost, calculateTotalPieces } from '../util/utils'
import ReactSignatureCanvas from 'react-signature-canvas'
import { blueLogo } from '../../style/colors'
import { connect } from 'react-redux'
import { RootState } from '../../store'
import ScaleLoader from 'react-spinners/ScaleLoader'
import { toast } from 'react-toastify'
import { ICart } from '../../types/cart'
import { ILabel } from '../../types/label'
import { CreateInvoiceProps } from './Checkout'

interface SignatureModalProps {
  show: boolean
  toggle: () => void
  createInvoice: ({ signature, cart, labels, buyerLocation }: CreateInvoiceProps) => void
  cart: ICart
  labels: ILabel[]
  buyerLocation: string
}

const SignatureModal = ({
  show,
  toggle,
  createInvoice,
  cart,
  labels,
  buyerLocation,
}: SignatureModalProps) => {
  const [showSignature, setShowSignature] = useState<boolean>(false)
  const [shouldSendEmail, setShouldSendEmail] = useState<boolean>(
    process.env.NODE_ENV !== 'development'
  )

  const [sigPad, setSigPad] = useState<ReactSignatureCanvas | null>(null)

  const confirmSignature = () => {
    if (sigPad?.isEmpty()) {
      toast.error(`Signature field cannot be empty.`)
    } else {
      const signature = sigPad?.getTrimmedCanvas().toDataURL('image/png')

      createInvoice({ signature, cart, labels, shouldSendEmail, buyerLocation })
      toggle()
    }
  }

  const clearSignature = () => {
    sigPad?.clear()
  }

  /**
   * Before we open the signature modal we write
   * the invoice pdf to the file stream
   * this takes 7.5 seconds for the file to be stored.
   * We need to bake a timeout into the invoice generation
   * flow to give the disk enough time to store the document.
   */

  const enableInvoice = () => {
    setShowSignature(true)
  }

  useState(() => {
    const timeout = setTimeout(enableInvoice, 750)

    return function () {
      clearTimeout(timeout)
    }
  })

  const handleClose = () => {
    setShowSignature(false)
    toggle()
  }

  return (
    <Modal show={show} centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Checkout</Modal.Title>
      </Modal.Header>
      {showSignature ? (
        <>
          <Modal.Body>
            {cart && (
              <>
                <Row>
                  <Col>
                    <strong> Total Pieces</strong>: {calculateTotalPieces(cart.items)}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <strong>Cart Total</strong>: {calculateTotalCost(cart.items, cart.credit)}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <strong> Customer</strong>: {cart.customer.email}
                  </Col>
                </Row>
              </>
            )}
          </Modal.Body>
          <Modal.Body>
            <div>
              I, the undersigned, affirm under penalty of law that the property that is subject to
              this transaction is not to the best of my knowledge stolen property. I certify that I
              am the legal owner of all materials sold or authorized by the legal owner to sell all
              materials sold. All materials sold are free and clear of all liens and encumbrances.
            </div>
            <br />
            <div>
              I warrant that this material does not contain any hazardous substances as defined by
              the federal and/or state law, and I agree to indemnify Purchaser/Payer if this is
              untrue.
            </div>
            <br />
            <div>
              Disclaimer and Waiver of liabilities for present and future deliveries. For mutual
              consideration, the customer and driver acknowledge and assume the risk involved in
              discharging scrap metal in the yard. The customer and driver release discharge and
              hold harmless MSLLC/ACRLLC its employees and its insurance carrier from any and all
              liability for damage both to person and property including but not limited to damage
              to motor vehicles while driving through the yard or loading or unloading scrap metal
              in the yard in connection with present and future delivery of scrap.
            </div>
          </Modal.Body>
          <Modal.Body>
            {process.env.NODE_ENV === 'development' && (
              <Form.Check
                type="checkbox"
                label="Send email"
                checked={shouldSendEmail}
                onChange={(e) => setShouldSendEmail(e.target.checked)}
              />
            )}
            <Row>
              <Col>
                <div style={{ fontWeight: 'bold', fontSize: 16 }}>Please sign below:</div>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Body>
            <div style={{ borderStyle: 'solid' }}>
              <ReactSignatureCanvas
                penColor={blueLogo}
                ref={(ref) => {
                  setSigPad(ref)
                }}
                canvasProps={{ width: 470, height: 200, className: 'sigCanvas' }}
              />
            </div>
          </Modal.Body>

          <Modal.Body>
            <Row>
              <Col>
                <Button onClick={() => clearSignature()}>Clear Signature</Button>
              </Col>
              <Col>
                <Button style={{ width: `100%` }} onClick={confirmSignature} variant="success">
                  Confirm and Send Invoice
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </>
      ) : (
        <Modal.Body>
          <Row>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p>One moment as we prepare your invoice.</p>
              <ScaleLoader color="#36D7B7" height={50} width={10} radius={4} margin={4} />
            </Col>
          </Row>
        </Modal.Body>
      )}
    </Modal>
  )
}

const mapState = (state: RootState) => {
  return {
    cart: state.cart.data,
    user: state.user,
    allUsers: state.admin.allUsers,
  }
}

export default connect(mapState)(SignatureModal)
